<template>

  <div class="row" ref="speakerForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="name"
                      rules="required"
                      name="The Name"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="name"
                                name="name"
                                fou
                                @keyup="generateSomeFields"
                                v-model="formData.name">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="url"
                      rules="required"
                      name="The URL"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="URL"
                                name="url"
                                fou
                                v-model="formData.url">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="website"
                      rules=""
                      name="The Website"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Website"
                                name="website"
                                fou
                                v-model="formData.website">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <fg-select
                      name="forums"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Select Editions"
                      :input-classes="'select-default'"
                      :label="'Editions'"
                      :list="builderData.forumList"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.forums">
                    </fg-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="position"
                      rules=""
                      name="The Position"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Position"
                                name="position"
                                fou
                                v-model="formData.position">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="facebook"
                      rules=""
                      name="The Facebook"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Facebook"
                                name="facebook"
                                fou
                                v-model="formData.facebook">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>
                <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right" >
                    <div slot="content"> (548 * 548)</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <single-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.image"
                    :accepted-files="imagesExtensions">
                  </single-file-uploader-file-manager>
                </div>
                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
                <div class="form-group pt-4">
                  <label>Is Featured</label>&nbsp;
                  <l-switch v-model="formData.is_featured">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/speakers/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import {Option, Select, Tooltip} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import SingleFileUploaderFileManager from "../../components/FileManager/SingleFileUploaderFileManager";



extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    SingleFileUploaderFileManager,
    PrimeUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      imagesExtensions: this.getImagesExtensions(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: "",
        position: "",
        content: "",
        image: "",
        is_active: true,
        is_featured: false,
        url: "",
        facebook: '',
        forums: [],
        website: '',
      },
      builderData: {
        forumList: [],
      },

    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.speakerForm
    });

    let data = {}
    this.axios.post("speakers/builder", data).then((response) => {
      this.builderData.forumList = response.data.forums;


      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Speaker";
        this.getSpeaker();
      } else {
        this.editMode = false;
        this.formTitle = "Add Speaker";
        this.formData.forums = response.data.currentForum ? [response.data.currentForum.id] : [];
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })
  },
  methods: {
    getSpeaker() {
      let data = {
        'id': this.id,
      };
      this.axios.post("speakers/get", data).then((response) => {
        this.formData = response.data;
        this.formData.forums = response.data.forums.map(forum => forum.id);
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Speaker Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("speakers/update/" + this.id, this.formData);
        successMessage = "Speaker Updated Successfully";
      } else {
        request = this.axios.post("speakers/create", this.formData);
        successMessage = "Speaker Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/speakers/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    generateSomeFields() {
      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "_");
      }
      this.formData.url = newUrl;
    },
  }
}
</script>
