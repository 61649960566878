<template>
  <el-dialog
    center
    :width="'900px'"
    @close="closeModal()"
    :visible="visible">
    <h5 style="text-align: center">File Manager</h5>
    <file-manager v-bind:settings="settings"></file-manager>
    <button @click="selectFiles()" class="btn btn-primary" style="width: 100px;">OK</button>
    <span style="color: red;" v-if="showValidationMessageError">{{ validationMessage }}</span>
  </el-dialog>

</template>


<script>
import {Dialog} from 'element-ui'

export default {
  name: 'file-manager-modal',
  components: {
    [Dialog.name]: Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    acceptedFiles: {
      type: Array,
      default: function () {
        return []
      }
    },
    allowedSelectCount: {
      type: Number,
      default: 1
    },
  },
  data() {

    return {
      settings: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        baseUrl: process.env.VUE_APP_LFM_AXIOS_BASE_URL,
      },
      showValidationMessageError: false,
      validationMessage: '',
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.resetFileManager();
    },

    selectFiles() {

      let acceptedFiles = this.acceptedFiles;
      let selectedFiles = this.$store.state.fm.left.selected.files;
      if (selectedFiles.length > this.allowedSelectCount) {
        this.validationMessage = `  You can't select more than ${this.allowedSelectCount} files`;
        this.showValidationMessageError = true;
        setTimeout(() => {
          this.showValidationMessageError = false
        }, 3000)
        return;
      }

      let typeNotAccepted = '';
      for (const file of selectedFiles) {
        console.log(file)
        let allow = acceptedFiles.findIndex(ext =>  ext.toUpperCase() === file.split('.').pop().toUpperCase())
        console.log(allow)
        if (allow === -1) {
          typeNotAccepted = file.split('.').pop();
          break;
        }
      }
      if (typeNotAccepted && typeNotAccepted !== '') {
        this.validationMessage = `   the file type  ${typeNotAccepted} not allowed`;
        this.showValidationMessageError = true;
        setTimeout(() => {
          this.showValidationMessageError = false
        }, 3000)
        return;
      }

      selectedFiles = selectedFiles.map(file =>  file);
      this.$emit('selectedFiles', selectedFiles);
      this.resetFileManager();
    },

    resetFileManager() {
      const type = 'files';
      const path = null;
      this.$store.commit(`fm/${this.$store.state.fm.activeManager}/changeSelected`, {type, path});
      this.$store.dispatch(`fm/${this.$store.state.fm.activeManager}/selectDirectory`, {path});
    }
  }
}
</script>
<style>
.fm .fm-body {
  min-height: 450px;
  max-height:450px;
}
.fm-content{
  min-height: 400px;
  max-height:400px;
}
</style>

